import moment from 'moment'

import store from '@/store'

import { statusesCancelled } from '@/functions/styleByStatus'

const isValidDataProperty = (dataProperty) => {
		const dataPropertyOptions = [
			'application_date',
			'order_date',
			'examination_date',
			'show_period_examinations',
			'show_period_params',
			'show_period_drugs',
			'date_created',
			'show_period_short_params',
			'creation_date',

			// events
			'datetimeCreated',
		]

		return dataPropertyOptions.includes(dataProperty)
	},
	isValidUnit = (unit) => {
		const unitOptions = ['days', 'hours', 'minutes', 'seconds']

		return unitOptions.includes(unit)
	}

export const limitObjects = (
		x,
		dataProperty,
		peroid,
		unit = 'days',
		disableDevDatetime = false
	) => {
		if (!isValidDataProperty(dataProperty)) {
			console.error(
				`[limitObjects]: No support for selected dataProperty="${dataProperty}" option`
			)
		}

		if (!isValidUnit(unit)) {
			console.error(
				`[limitObjects]: No support for selected unit="${unit}" option`
			)
		}

		const devDatetime = null

		return x && x.length > 0
			? x.filter(
					(e) =>
						(devDatetime && !disableDevDatetime
							? moment(devDatetime)
							: moment()
						).diff(moment(e[dataProperty]), unit) <=
						(peroid)
			  )
			: []
	},
	getMomentNow = function (disable_dev_datetime = false) {
		const dev_datetime = null,
			m =
				dev_datetime && !disable_dev_datetime
					? moment(dev_datetime)
					: moment()
					
			return m
	},
	retFromat = 'YYYY-MM-DD HH:mm:ss.000000+01:00',
	getDateNow = function (disable_dev_datetime = false) {
		const m = getMomentNow(disable_dev_datetime)
		
		return m.format(retFromat)
	},
	getDateFromPastV2 = function (
		peroid = 0,
		unit = 'days',
		disable_dev_datetime = false,
		code = null
	) {
		const m = getMomentNow(disable_dev_datetime)
		let r = null
					
		if(code){
			if(code === 'ai')
				r = m
					// .subtract(24, 'hours')
					.subtract(24, 'hours')
					.set({hour:15,minute:0,second:0,millisecond:0})
		}
		
		if(r === null)
			r = m
				.subtract(
					peroid,
					unit
				)
				
		return r.format(retFromat)
	
	},
	getFilterForCollection = function (collectionName = null, range = 'all') {
		if (!collectionName)
			throw new Error(`[getFilterForCollection] no collectionName`)

		//TODO add collection services
		const paramsSelector = {
				result: { $nin: [null] },
				gui_type: 'params',
			},
			paramsOnlyNEWS2Selector = {
				...paramsSelector,
				// result: /NEWS/,
				result_form_contain_news: true,
			},
			noParamsSelector = {
				$not: { gui_type: 'params' },
			},
			config = [
				{
					collectionName: 'examinations',
					range: 'all',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: {
						status_name: { $nin: statusesCancelled },
					},
				},
				{
					collectionName: 'examinations',
					range: 'params-o',
					filterDate: {
						dataProperty: 'order_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'all-no-params',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: noParamsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'params-o-news',
					filterDate: {
						dataProperty: 'examination_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsOnlyNEWS2Selector,
				},
				{
					collectionName: 'examinations',
					range: 'params-e',
					filterDate: {
						dataProperty: 'examination_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'lab-o',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: {
						gui_type: { $in: ['laboratory'] },
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'all',
					filterDate: {
						dataProperty: ['application_date', 'order_date'],
						peroid: 'show_period_drugs',
						unit: 'days',
					},
					selectorRaw: {
						order_date: { $gt: true }, // Note that sorted fields also have to be selected in the selector.
					},
				},
				{
					collectionName: 'treatments',
					range: 'all',
					filterDate: {
						dataProperty: ['creation_date', 'modification_date'],
						peroid: 'show_period_descriptive_data',
						unit: 'days',
					},
				},
				{
					collectionName: 'treatments',
					range: 'only-rozpoznanie-wstepne',
					filterDate: {
						dataProperty: 'creation_date', //['creation_date',/* 'modification_date' */],
						peroid: 'show_period_descriptive_data',
						unit: 'days',
					},
					selectorRaw: {
						name: { $regex: '^Rozpoznanie wstępne( .*)?$' },
						/*name: { $nin: [null] },
						$or: [
							{ name: 'Rozpoznanie wstępne' },
							{
								name: 'Rozpoznanie wstępne F/PZ-LiH-01/Z10/E001 - 06 - Historia Choroby Ogólna',
							},
						],*/
						//TODO use regex?
						// name: /Rozpoznanie wstępne/
					},
				},
				{
					collectionName: 'wards',
					range: 'only_active',
					selectorRaw: {
						is_active: true,
						is_assigned: true,
					},
				},
				{
					collectionName: 'stays',
					range: 'only_active',
					selectorRaw: {
						stop_date: null,
						patient_uuid: { $gt: true }, // not compatible with rxdb@10.5.4, for this version replace true with 0
					},
				},
				{
					collectionName: 'zwr_patient_alerts',
					range: 'only_active',
					// filterDate: {
					// 	dataProperty: 'examination_date',
					// 	peroid: 'active_period_zwr_patient_alert',
					// 	unit: 'hours',
					// },
					selectorRaw: {
						is_active: true,
					},
				},
				{
					collectionName: 'ward_persons',
					range: 'only_for_logged_user',
					selectorRaw: {
						person_id_ref: store?.state?.CurrentUser?.instance?.id,
					},
				},
				{
					collectionName: 'treatments',
					range: 'all_aidesc',
					filterDate: {
						dataProperty: ['creation_date', 'modification_date'],
						aiRange: true,
					},
					selectorRaw: {
						name: { $regex: '^Obserwacje pielęgniarskie( .*)?$' },
					}
				},
				{
					collectionName: 'examinations',
					range: 'all-no-params_aidesc',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						aiRange: true,
					},
					selectorRaw: noParamsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'params-e_aidesc',
					filterDate: {
						dataProperty: 'examination_date',
						aiRange: true,
					},
					selectorRaw: paramsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'params-e-lab_aidesc',
					filterDate: {
						dataProperty: 'examination_date',
						aiRange: true,
					},
					selectorRaw: {
						gui_type: 'laboratory'
					},
				},
				{
					collectionName: 'examinations',
					range: 'params-e-con_aidesc',
					filterDate: {
						dataProperty: 'examination_date',
						aiRange: true,
					},
					selectorRaw: {
						gui_type: 'consultation'
					},
				},
				{
					collectionName: 'examinations',
					range: 'params-e-rad_aidesc',
					filterDate: {
						dataProperty: 'examination_date',
						aiRange: true,
					},
					selectorRaw: {
						gui_type: 'radiology'
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'all_aidesc',
					filterDate: {
						dataProperty: ['application_date', 'order_date'],
						aiRange: true,
						aiRangePlusFuture: true,
					},
					selectorRaw: {
						order_date: { $gt: true }, // Note that sorted fields also have to be selected in the selector.
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'all-old_aidesc',
					filterDate: {
						dataProperty: ['application_date', 'order_date'],
						aiRange: true,
						aiRangeOnlyOld: true,
					},
					selectorRaw: {
						order_date: { $gt: true }, // Note that sorted fields also have to be selected in the selector.
					},
				},
			// 	{
			// 		collectionName: 'drug_applications',
			// 		range: 'all_aidesc',
			// 		filterDate: {
			// 			dataProperty: ['application_date', 'order_date'],
			// 			aiRangePlusFuture: true,
			// 		},
			// 		selectorRaw: {
			// 			order_date: { $gt: true }, // Note that sorted fields also have to be selected in the selector.
			// 		},
			// 	},
			]

		let i = config.findIndex((e) =>
			e.collectionName === collectionName && e?.range === range
				? true
				: false
		)

		if (i === -1)
			throw new Error(
				`[getFilterForCollection] no config for collection '${collectionName}' and range '${range}'`
			)
		else {
			const { filterDate, selectorRaw } = config[i] || {},
				selector = { ...selectorRaw }

			if (filterDate?.dataProperty) {
				let code = null
				
				if(filterDate?.aiRange)
					code = 'ai'
					
				const dateFromPast = getDateFromPastV2(
						filterDate.peroid,
						filterDate.unit,
						false, 
						code
					)
				
				if (typeof filterDate?.dataProperty == 'string'){
					selector[filterDate.dataProperty] = {
						$gte: dateFromPast,
						$lte: getDateNow(),
					}
					
					if(filterDate?.aiRangeOnlyOld)
						selector[filterDate.dataProperty] = {
							$lte: dateFromPast,
						}
					else if(filterDate?.aiRangePlusFuture)
						selector[filterDate.dataProperty] = {
							$gte: dateFromPast,
						}
				} else if (typeof filterDate?.dataProperty == 'object') {
					const or = filterDate?.dataProperty.map((dP) => {
						let o = {
							[dP]: {
								$gte: dateFromPast,
								$lte: getDateNow(),
							},
						}
						
						if(filterDate?.aiRangeOnlyOld)
							o = {
								[dP]: {
									$lte: dateFromPast,
								},
							}
						else if(filterDate?.aiRangePlusFuture)
							o = {
								[dP]: {
									$gte: dateFromPast,
								},
							}
							
						return o
					})

					// const or = filterDate?.dataProperty.map((dP) => ({
					// 	$and: [
					// 		{
					// 			[dP]: {
					// 				$exists: true,
					// 			},
					// 		},
					// 		{
					// 			[dP]: {
					// 				$gte: getDateFromPast(
					// 					filterDate.peroid,
					// 					filterDate.unit
					// 				),
					// 			},
					// 		},
					// 	],
					// }))

					if (selector.$or && !selector.$and) {
						selector.$and = []

						selector.$and.push({
							$or: or,
						})
						selector.$and.push({
							$or: selector.$or,
						})

						delete selector.$or
					} else if (!selector.$or)
						selector.$or = [...(selector.$or || []), ...or]
				}
			}

			console.debug(
				`[getFilterForCollection]`,
				collectionName,
				range,
				selector
			)

			return selector
		}
	},
	getFirst = function (arr, amount) {
		return arr?.slice(0, amount) || arr
	},
	getDaysBetweenDates = function (startDate, endDate) {
		var now = startDate.clone(),
			dates = []

		while (now.isSameOrBefore(endDate)) {
			dates.push(now.format('YYYY-MM-DD'))
			now.add(1, 'days')
		}
		return dates
	}

export default limitObjects
