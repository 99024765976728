import { v4 as uuidv4 } from 'uuid'

import { $classes } from '@/main'
import store from '@/store'

import { createEvent, createEventFromCode } from '@/functions/events'
import { isOnline } from '@/functions/network'
import { promiseAllSynchronous } from '@/functions/promise'

const fillReport = function (report = {}) {
		report.console_log = console.storedLogs
		report.sync_log = store.state.CurrentLog.events
		report.connection_status = store.state.CurrentUser.network_status
		report.last_commit_hash = process.env.VUE_APP_COMMIT_HASH
		report.app_version = store.getters.appVersion

		if (!report.email && store.state.CurrentUser?.instance?.login)
			report.email = store.state.CurrentUser.instance.login
		if (!report.url) report.url = window.location.href
		if (!report.submission_method) report.submission_method = 'manual'

		if (!report.files) report.files = []

		if (!report.creation_date) report.creation_date = new Date()
		if (!report.title) report.title = 'Zgłoszenie uzytkownika'

		report.uuid = uuidv4()

		return report
	},
	validateReport = function (report = {}) {
		const requiredProps = [
			'console_log',
			'sync_log',
			'connection_status',
			'url',
			'last_commit_hash',
			'app_version',
			// 'email', //ANONYMOUS
		]

		requiredProps.forEach((prop) => {
			if (!Object.keys(report || {}).includes(prop))
				throw new Error(`Missing required '${prop}' in report.`)
		})
	},
	keyPendingReports = 'pending-reports',
	setPendingReports = function (value = []) {
		localStorage.setItem(keyPendingReports, JSON.stringify(value))
	},
	getPendingReports = function () {
		return JSON.parse(localStorage.getItem(keyPendingReports) || '[]')
	},
	appendPendingReport = function (report = {}) {
		const reports = getPendingReports()

		reports.push(report)

		setPendingReports(reports)
	},
	markPendingReportAsSend = function (report = {}) {
		const reports = getPendingReports(),
			reportIndex = reports.findIndex((r) => r.uuid === report.uuid)

		reports.splice(reportIndex, 1)

		setPendingReports(reports)
	},
	sendPendingReport = async function (report = {}) {
		await sendReport(report)
		markPendingReportAsSend(report)
	},
	sendReport = async function (report = {}) {
		try {
			await store.dispatch('CurrentUser/requireAuthenticatedClasses')
			
			if(!$classes?.ReportProblem?.create_report){
				console.debug(`[sendReport] no classes`)
				return
			}

			const response = await $classes.ReportProblem?.create_report({
				data: report,
			})
			if (console.storedLogs) console.storedLogs.length = 0

			await createEvent({
				status: 'Success',
				details: `Utworzono zgłoszenie problemu o ID.: ${response}`,
			})
		} catch (e) {
			const { message } = e
			
			console.error(e)
			
			if (exceptions.includes(message)) {
				appendPendingReport(report)

				await createEventFromCode('report-problem-failed-send-later')
			} else 
				await createEventFromCode('report-problem-failed')
		}
	},
	exceptions = [
		'No internet access.',
		'No connection to the server.',
		'Connection speed is to low.',
		/Request failed with status code*./,
		'Wykryto nową wersję aplikacji, proszę o zaktualizowanie',
		'Network Error',
		
	]

export const createReport = async function (report = {}) {
		try {
			report = fillReport(report)
			validateReport(report)

			await store.dispatch('CurrentUser/updateNetworkStatusWithError')

			return sendReport(report)
		} catch (e) {
			const { message } = e
			
			console.error(e)
			
			if (message === 'No internet access.') {
				appendPendingReport(report)

				await createEventFromCode('report-problem-failed-send-later')
			}
		}
	},
	sendPendingReports = async function () {
		try {
			await isOnline()

			const reports = getPendingReports()
			await promiseAllSynchronous(reports, sendPendingReport)
		} catch (e) {
			console.error(e)
		}
	},
	errorToReport = async function (err = {}) {
		try {
			const { message } = err

			if (typeof message !== 'undefined') {
				const { stack, name } = err
				
				const exceptionsString = exceptions.filter(ex => typeof ex === 'string'),
					exceptionsRegex = exceptions.filter(ex => ex instanceof RegExp)
					
				let isException = false
				
				if(exceptionsString.includes(message))
					isException = true
				else 
					exceptionsRegex.forEach(ex => {
						if(!isException && ex.test(message))
							isException = true
					})

				if (!isException) {
					console.debug(
						`[errorToReport] creating report for error: ${message}`
					)

					await createReport({
						title: `Wystąpił błąd: ${message}`,
						message: `Name: ${name} | Stack: ${stack}`,
						submission_method: 'automatic',
					})
				} else console.debug(`[errorToReport] exception found for: ${message}`)
			}
		} catch (e) {
			console.warn(e)
		}
	}
