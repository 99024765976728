import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
class UpdateApp extends VuexModule {

	serverAppVersion = null
	@Action
	assignServerAppVersion(ver) {
		this.context.commit('assignServerAppVersionMutation', ver)
	}
	@Mutation
	assignServerAppVersionMutation(ver) {
		this.serverAppVersion = ver
	}
	get getServerAppVersion() {
		return this.serverAppVersion
	}

	ShowSnackbarState = false
	@Action
	ShowSnackbarAction(val) {
		this.context.commit('ShowSnackbarMutation', val)
	}
	@Mutation
	ShowSnackbarMutation(val) {
		this.ShowSnackbarState = val
	}
	get showSnackbar() {
		return this.ShowSnackbarState
	}
}

export default UpdateApp
