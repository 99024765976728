import { version } from '../../package'
import Vue from 'vue'
import Vuex from 'vuex'

import CurrentUser from '@/store/CurrentUser'
import CurrentLog from '@/store/CurrentLog'
import UpdateApp from '@/store/UpdateApp'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		packageVersion: version || '0'
	},
	getters: {
		appVersion: (state) => {
			return state.packageVersion
		}
	},
	mutations: {},
	actions: {},
	modules: {
		CurrentUser,
		CurrentLog,
		UpdateApp,
	},
})
