<template>
	<v-app>
		<RefreshPage />
		<div id="app" data-app>
			<AppLayout>
				<router-view />
				<div v-if="showVersion" class="app-version-preview">
					<span>
						{{
							currentAppVersion
								? `${$t(
									'Application version'
								)}: ${currentAppVersion}`
								: $t('No local version of the application')
						}}
					</span>
					<v-btn x-small depressed color="primary" @click="refresh">
						<span>
							{{ $t('Download App') }}
						</span>
					</v-btn>
				</div>
			</AppLayout>
		</div>
	</v-app>
</template>

<script>
	import AppLayout from '@/layouts/AppLayout'
	import RefreshPage from '@/components/mobile/RefreshPage.vue'

	import { locationReload } from '@/functions/appVersion.js'

	export default {
		name: 'App',
		components: { AppLayout, RefreshPage },
		computed: {
			currentAppVersion() {
				return this.$store.getters.appVersion
			},
			showVersion() {
				if (this.$route.path == '/') return true
				else return false
			},
		},
		methods: {
			refresh() {
				locationReload()
			},
		},
		async mounted() {
			await this.$store.dispatch('CurrentLog/restore')
			await this.$store.dispatch('CurrentUser/updateNetworkStatus')
		},
	}
</script>

<style lang="scss">
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	cursor: pointer;
}

@import '@/assets/scss/fontawesome';

@import '@/assets/scss/fonts';

@import '@/assets/scss/bootstrap';

@import '@/assets/scss/korban_general';

.v-application {
	// display: unset !important;
}
.notificationCenter {
	z-index: 15000 !important;
}
.app-version-preview {
	position: fixed;
	left: 0;
	bottom: 0;
	padding: 8px 20px;
	z-index: 10;
	border-radius: 4px;
	backdrop-filter: blur(20px);
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
