import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import { routes, titleDefault } from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
})

const originalPush = router.push
router.push = function (...args) {
	return originalPush.call(this, ...args)?.catch((error) => {
		if (error) {
			console.warn(`consumed router exception: ${error}`)
		} else {
			console.warn(
				`consumed unknown router exception ${new Error().stack.replace(
					/^[\s\S]+?at/,
					'at'
				)}`
			)
		}
	})
}

router.beforeEach(async (to, from, next) => {
	await store.dispatch('CurrentUser/updateNetworkStatus')

	if (
		to.matched.some((record) => record.meta.requiresAuth) &&
		from.name !== 'mb-login'
	) {
		if (!store.state.CurrentUser.instance) {
			/* get last logged user from cache */
			try {
				await store.dispatch('CurrentUser/get_user_data_from_cache')
			} catch (error) {
				console.error(error)
			}
		}

		if (!store.state.CurrentUser.instance)
			await store.dispatch('CurrentUser/requireUserInfo')

		if (!store.state.CurrentUser.instance)
			await store.dispatch('CurrentUser/login_with_token')

		if (
			!store.state.CurrentUser.instance &&
			to.name !== 'mb-login' &&
			from.name !== 'mb-login'
		) {
			console.debug('Przekierowywanie do strony logowania')
			next({ name: 'mb-login', query: { redirect: to.path } })
		}
	}

	if (to.matched.some((record) => record.meta.requiresLocation)) {
		if (
			(!store.state.CurrentUser?.ward ||
				!store.state.CurrentUser?.coordinator) &&
			to.name !== 'mb-login-select' &&
			from.name !== 'mb-login-select'
		) {
			console.debug(
				'Przekierowywanie do strony wyboru jednostki oraz nauczyciela'
			)
			next({ name: 'mb-login-select', query: { redirect: to.path } })
		}
	}

	if (to.matched.some((record) => record.meta.requireAuthenticatedClasses)) {
		await store.dispatch('CurrentUser/requireAuthenticatedClasses')
	}

	if (to.matched.some((record) => record.meta.requirePublicClasses)) {
		await store.dispatch('CurrentUser/requirePublicClasses')
	}

	next()
})

router.afterEach((to /* from */) => {
	Vue.nextTick(() => {
		if (to.meta.title) {
			const title = to.meta.title
				? ` - ${window.$vm.$t(to.meta.title)}`
				: ''
			document.title = `${titleDefault}${title}`
		}
	})
})

export default router
