import i18next from 'i18next'
import VueI18next from '@panter/vue-i18next'
import Vue from 'vue'

/* Do poczytania

https://phrase.com/blog/posts/vue-translation-with-vue-i18next/
https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n - i18next to wrapper do tego
https://www.i18next.com
https://www.i18next.com/translation-function/plurals
https://jsfiddle.net/paweu/054bLt6w/  -- pluralizacja (formy mnogie dla PL)
https://github.com/kamilglod/i18next-loader - nie uzywane

*/

/**
 * $tt instance function
 * translates templates like "{{ translate string1 }} + {{ translate string 2 }}"
 */

const TplTranslate = {
	install(Vue, options) {
		options // silence lint
		Vue.prototype.$tt = function (value) {
			var ret = ''
			if (!value) return ret
			var parts = value.split(/({{[^}]+}})/)
			if (parts.length == 1 && parts[0][0] != '{') {
				return this.$t(parts[0])
			}
			for (var i = 0; i < parts.length; i++) {
				var part = parts[i]
				if (part.match(/^{{(.*)}}$/)) {
					ret += this.$t(part.replace(/^{{(.*)}}$/, '$1'))
				} else {
					ret += part
				}
			}
			return ret
		}
	},
}

Vue.filter('translate', (value, options) => {
	if (!value) return ''
	return i18next.t(value, options)
})

export const translate = function (value, options) {
	return i18next.t(value, options)
}

Vue.use(TplTranslate)

Vue.use(VueI18next)

function loadLocaleResources() {
	const locales = require.context(
		'./locales',
		true,
		/locale_[A-Za-z0-9\-_,\s]+\.json$/i
	)
	const resources = {}
	locales.keys().forEach((key) => {
		const matched = key.match(/locale_([A-Za-z0-9\-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			//console.debug(locale);
			resources[locale] = { translation: locales(key) }
		}
	})
	return resources
}

i18next.init({
	lng: 'pl',
	fallbackLng: 'en',
	nonExplicitWhitelist: true,
	keySeparator: false,
	whitelist: ['pl', 'en'],
	//load: 'currentOnly',
	debug: true,
	/*resources: {
    en_US: { translation: locales.en_US },
    pl_PL: { translation: locales.pl_PL },
  },*/
	resources: loadLocaleResources(),
})

window.i18next = i18next
export default new VueI18next(i18next)
