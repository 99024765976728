<template>
	<div id="main" class="d-flex justify-content-center align-items-center">
		<h1
			class="mr-3 pr-3 align-top border-right inline-block align-content-center"
		>
			{{ 'Maintenace break' | translate }}
		</h1>
		<div class="inline-block align-middle">
			<h2 id="desc" class="font-weight-normal lead">
				{{ msg }}
			</h2>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Error500',
		props: ['msg'],
	}
</script>

<style lang="scss" scoped>
	// @import '~bootstrap/scss/bootstrap.scss';
	#main {
		height: 100vh;
	}
</style>