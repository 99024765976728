import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import { createEventFromCode, createEvent } from '@/functions/events'
import { isOnline } from '@/functions/network'
import { setAuth, getTokenBy } from '@/functions/auth'
import { sendPendingReports } from '@/functions/reportProblem'

import { $classes } from '@/main'
import { translate } from '@/i18n'

import {
	getToken,
	clear_auth_data,
} from '@/functions/auth'

import { removeCalculatedParams } from '@/functions/paramsOffline'

import { getCircularReplacer } from '@/functions/json'

@Module({ namespaced: true })
class CurrentUser extends VuexModule {
	instance = null
	sms_request_id = null
	onesignal_skd_initialized = false
	key = 'user'
	network_status = 'online'
	
	//list
	wards = null
	
	//selected
	ward = null
	coordinator = null
	
	//form
	new_opinion = null
	
	//stats
	stats_info = null

	@Mutation
	setInstance(user) {
		this.instance = user
	}
	
	@Mutation
	setStatsInfo(d) {
		this.stats_info = d
	}
	
	@Mutation
	setNewOpinion(new_opinion) {
		this.new_opinion = {...this.new_opinion, ...new_opinion}
	}
	@Mutation
	resetNewOpinion() {
		this.new_opinion = null
	}
	
	@Mutation
	setWards(wards) {
		this.wards = wards
	}

	@Mutation
	setNetworkStatus(status) {
		this.network_status = status
	}

	@Mutation
	setOneSignalInitialized() {
		this.onesignal_skd_initialized = true
	}

	@Action({ rawError: true })
	async removeCalculatedParams() {
		removeCalculatedParams()
	}
	
	@Action({ rawError: true })
	async updateNetworkStatusWithError() {
		try {
			await isOnline()
			
			if(this.network_status === 'offline'){
				await createEventFromCode(
					'network-internet-connection-back-success'
				)
				await sendPendingReports()
			}
			
			this.context.commit('setNetworkStatus', 'online')
		} catch (e) {
			this.context.commit('setNetworkStatus', 'offline')
			
			throw e
		}
	}

	@Action({ rawError: true })
	async updateNetworkStatus() {
		try {
			await this.context.dispatch('updateNetworkStatusWithError')
		} catch (e) {
			console.warn(e)
		}
	}

	@Mutation
	saveUserDataWithoutAuth(data) {
		const key = this.key

		let dataOld = localStorage.getItem(key)
				? JSON.parse(localStorage.getItem(key))
				: {},
			dataNew = {
				...dataOld,
				...data,
				last_update: new Date().toLocaleTimeString('pl-PL'),
			}

		let dataStringified = JSON.stringify(dataNew, getCircularReplacer())

		/* localstorage */
		localStorage.setItem(key, dataStringified)
	}

	@Mutation
	clearUserData() {
		/* localstorage */
		// localStorage.removeItem(this.key)

		// delete only auth
		if (localStorage.getItem(this.key)) {
			// const dataOld = JSON.parse(localStorage.getItem(this.key)),
			// 	dataNew = {
			// 		instance: dataOld.instance,
			// 		last_update: dataOld.last_update,
			// 	}

			// /* localstorage */
			// localStorage.setItem(this.key, JSON.stringify(dataNew))
			localStorage.removeItem(this.key)
		}
		
		clear_auth_data()
	}

	@Mutation
	restoreUserData() {
		const key = this.key,
			d = localStorage.getItem(key)
			
		if (d) {
			let data = JSON.parse(d)

			if (data?.instance?.id) {
				console.debug(
					`[restoreUserData] Restoring user data from cache`
				)
				this.instance = data.instance
			} else console.debug(`[restoreUserData] No user data in cache.`)
			
			if (data?.location) {
				console.debug(
					`[restoreUserData] Restoring user location data from cache`
				)
				const {ward, coordinator} = data?.location
				this.ward = ward
				this.coordinator = coordinator
				
			} else console.debug(`[restoreUserData] No user location data in cache.`)

		}
	}

	@Action({ rawError: true })
	async requireUserInfo() {
		if (this.instance === null) {
			await this.context.dispatch('requirePublicClasses')

			console.debug(`[requireUserInfo] Getting current user instance...`)
			const user = await $classes?.Person?.current_user() //TODO add condition
			if (user?.__id) {
				this.context.commit('setInstance', user)
				await this.context.dispatch('requireAuthenticatedClasses')

				console.debug(`[requireUserInfo] success.`)
			} else {
				console.debug(`[requireUserInfo] fail.`)
			}
		}
	}

	@Action({ rawError: true })
	async requireAuthenticatedClasses() {
		const token = await getToken()

		if (token) {
			console.debug(
				`[requireAuthenticatedClasses] started`
			)
			await $classes.loadAuthenticated({ token: token })
			
			console.debug(
				`[requireAuthenticatedClasses] finished`
			)
		}
	}

	@Action({ rawError: true })
	async requirePublicClasses() {
		if (!$classes?.Person) {
			console.debug(
				`[requirePublicClasses] started`
			)
			try {
				await $classes.loadPublic()
				console.debug(
					`[requirePublicClasses] finished`
				)
			} catch (e) {
				console.log({ ...e })
			}
		}
	}
	
	@Action({ rawError: true })
	async getWards() {
		if(!$classes.Ward?.list)
			await this.context.dispatch('requireAuthenticatedClasses')
	
		const wards = await $classes?.Ward?.list()
		
		this.context.commit('setWards', wards)
	}
	
	@Action({ rawError: true })
	async getStats() {
		if(!$classes?.Feedback?.user_info)
			await this.context.dispatch('requireAuthenticatedClasses')
			
		const user_info = await $classes?.Feedback?.user_info()
		this.context.commit('setStatsInfo', user_info)
	}

	@Action({ rawError: true })
	async get_user_data_from_cache() {
		if (this.instance == null) {
			const token = await getToken()
			
			if(token)
				this.context.commit('restoreUserData')
		}
	}
	
	@Action({ rawError: true })
	updateNewOpinion(data = null) {
		this.context.commit(
			'setNewOpinion',
			data,
		)
	}
	
	@Action({ rawError: true })
	async sentNewOpinion(data = null) {
		if(this.new_opinion?.user_id) {
			if(!$classes?.Feedback?.create_feedback)
				await this.context.dispatch('requirePublicClasses')
				
			await $classes?.Feedback?.create_feedback_anonymous(this.new_opinion)
		} else {
			if(!$classes?.Feedback?.create_feedback)
				await this.context.dispatch('requireAuthenticatedClasses')
				
			await $classes?.Feedback?.create_feedback(this.new_opinion)
		}
	
		this.context.commit(
			'resetNewOpinion',
			data,
		)
	}
	
	@Action({ rawError: true })
	async checkOpinion(fromDate) {
		if(!$classes?.Feedback?.check_feedback)
			await this.context.dispatch('requirePublicClasses')
			
		try {
			const {new_opinion} = this
			
			if(new_opinion && fromDate){
				const res = await $classes?.Feedback?.check_feedback({...new_opinion, from_date: fromDate})
				const { count } = res
				
				return count
			}
		
		} catch (error) {
			console.error(error)
		}	
	}

	@Action({ rawError: true })
	async userDataUpdate() {
		const token = await getToken()

		if (token) {
			await $classes.loadAuthenticated({ token: token })
			const user = await $classes.Person.current_user()

			this.context.commit('saveUserDataWithoutAuth', {
				instance: user,
			})
			this.context.commit('setInstance', user)
		} else
			throw new Error(
				`Access token and refresh token expired, please login by online method.`
			)
	}

	@Action({ rawError: true })
	async login({ login, pass, remember_me, account_type }) {
		console.debug(login, remember_me, account_type)

		let token = null

		try {
			token = await getTokenBy({login, pass})
		} catch (error) {
			if (!error?.response) {
				throw new Error(
					`Login online method is out of service, please check internet access or login by offline method.`
				)
			} else {
				createEvent({
					status: 'Fail',
					details: error?.response?.data?.error_description && translate(error?.response?.data?.error_description) || 'Nieznany błąd',
					debug: error,
				})
				
				throw error
			}
		}

		if (token) {
			const {
				access_token,
			} = token

			await $classes.loadAuthenticated({ token: access_token })
			const user = await $classes.Person.current_user()
			
			this.context.commit('clearUserData')
			
			setAuth(token)
			
			this.context.commit('saveUserDataWithoutAuth', {
				instance: {
					id: user.id,
					email: user.email,
					role: user.role,
					full_name: user.full_name,
					album_number: user.album_number,
					person_language: user.person_language,
					profile_image: user._original.profile_image,
				},
			})
			
			this.context.commit('setInstance', user._original)

			await createEventFromCode('user-login-online-success')
		}
	}
	
	@Mutation
	setStayDetails({ward, coordinator}) {
		this.ward = ward
		this.coordinator = coordinator
	}
	
	@Action({ rawError: true })
	selectStayDetails({ward=null, coordinator=null}){
		this.context.commit('setStayDetails', {ward, coordinator})
		
		this.context.commit('saveUserDataWithoutAuth', {
			location: {ward, coordinator}
		})
	}

	@Action({ rawError: true })
	async login_with_token() {
		console.debug(`[login_with_token] starting...`)

		const token = await getToken()

		if (token) {
			await $classes.loadAuthenticated({ token: token })
			const user = await $classes.Person.current_user()

			this.context.commit('saveUserDataWithoutAuth', {
				instance: user,
			})
			this.context.commit('setInstance', user)

			await createEventFromCode('user-login-online-success')

			console.debug(`[login_with_token] logged`)
		} else {
			console.debug(`[login_with_token] no token`)
		}
	}

	@Action({ rawError: true })
	async proxy_login({ accessToken }) {
		await $classes.loadAuthenticated({ token: accessToken })
		const user = await $classes.Person.current_user()

		this.context.commit('setInstance', user)
	}

	@Action({ rawError: true })
	async logout() {
		console.debug('[logout] initializing...')

		if ($classes?.Person)
			try {
				await $classes.Person?.logout()
			} catch (error) {
				console.error(error)
			}

		await createEventFromCode('user-logout-success')
		this.context.commit('clearUserData')
		this.context.commit('setInstance', null)
		this.context.commit('setStatsInfo', null)
		this.context.commit('resetNewOpinion')
		this.context.commit('selectStayDetails')
		
		console.debug('[logout] done.')
	}

	@Action({ rawError: true })
	async get_user_data() {
		const user = await $classes.Person.current_user()
		this.context.commit('setInstance', user._original)
	}

	@Action({ rawError: true })
	initOneSignalSDK() {
		if (
			!this.onesignal_skd_initialized &&
			this.instance &&
			this.instance.role == 'Runner'
		) {
			window.OneSignal = window.OneSignal || []
			window.OneSignal.push(() => {
				window.OneSignal.init({
					appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
					allowLocalhostAsSecureOrigin:
						process.env.NODE_ENV !== 'production',
				})
			})

			console.log('onesignal sdk initialized')

			this.context.commit('setOneSignalInitialized')
		}
	}
}

export default CurrentUser
