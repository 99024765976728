const devStatusesMissingStyles = []

const config = [
	{
		statuses: [
			'Approved','approved',
		],
		data: {
			'bg-color': 'green',
			'icon-class': 'fa-regular fa-circle-check',
		},
	},
	{
		statuses: [
			'Rejected','rejected',
		],
		data: {
			'bg-color': 'red',
			'icon-class': 'fa-regular fa-circle-xmark',
		},
	},
	{
		statuses: ['Pending','pending'],
		data: {
			'bg-color': 'blue',
			'icon-class': 'fa-regular fa-circle-arrow-right',
		},
	},
	{
		statuses: [null, undefined],
		data: {
			'bg-class': null,
			'icon-class': null,
		},
	},
	{
		statuses: [],
		data: {
			'bg-class': null,
			'icon-class': null,
		},
	},
]

export const getStyleByStatus = function (status) {
		let i = config.findIndex((e) =>
			e['statuses']?.includes(status) ? true : false
		)

		if (i >= 0) return config[i].data
		else if (process.env.NODE_ENV === 'development')
			if (!devStatusesMissingStyles.includes(status)) {
				devStatusesMissingStyles.push(status)
				console.debug(
					'Brak zdefiniowanego stylu dla statusu: ' +
						JSON.stringify(devStatusesMissingStyles)
				)
			}

		return config[config.length - 1].data
	},
	getStyleByNorm = function (norm) {
		let i = config.findIndex((e) =>
			e['norm']?.includes(norm) ? true : false
		)

		if (i >= 0) return config[i].data

		return config[config.length - 1].data
	},
	statusesResults = config[0].statuses,
	statusesPending = config[2].statuses,
	statusesCancelled = config[1].statuses

export default getStyleByStatus
