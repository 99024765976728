export const titleDefault = 'SympatoMED'

export const routes = [
	{
		path: '/',
		name: 'mb-start',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/Start.vue'),
		meta: {
			// layout: 'AppLayoutMobileStart',
			requiresAuth: false,
			requiresLocation: false,
			isMobile: true,
		},
	},
	{
		path: '/mb/login',
		name: 'mb-login',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/login'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: false,
			requiresLocation: false,
			requirePublicClasses: true,
			// requireAuthenticatedClasses: true,
			title: `Logowanie`,
			isMobile: true,
		},
	},
	{
		path: '/mb/login-select',
		name: 'mb-login-select',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/login/Select.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: true,
			requiresLocation: false,
			requirePublicClasses: true,
			title: `Wybór`,
			isMobile: true,
		},
	},
	{
		path: '/mb/register',
		name: 'mb-register',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/register'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: false,
			requiresLocation: false,
			requirePublicClasses: true,
			title: `Rejestracja`,
			isMobile: true,
		},
	},
	{
		path: '/mb/register-completed',
		name: 'mb-register-completed',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/register/Completed.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: false,
			requiresLocation: false,
			requirePublicClasses: true,
			title: `Rejestracja`,
			isMobile: true,
		},
	},
	{
		path: '/mb/remind-password',
		name: 'mb-remind-password',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/remind_password'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: false,
			requiresLocation: false,
			requirePublicClasses: true,
			title: `Przypominanie hasła`,
			isMobile: true,
		},
	},
	{
		path: '/mb/remind-password-completed',
		name: 'mb-remind-password-completed',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/remind_password/Completed.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: false,
			requiresLocation: false,
			requirePublicClasses: true,
			title: `Przypominanie hasła`,
			isMobile: true,
		},
	},
	{
		path: '/mb/password-recovery',
		name: 'mb-password-recovery',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/password_recovery'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: false,
			requiresLocation: false,
			requirePublicClasses: true,
			title: `Odzyskiwanie hasła`,
			isMobile: true,
		},
	},
	{
		path: '/mb/start',
		name: 'mb-panel',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel'),
		meta: {
			layout: 'AppLayoutMobileWithBottomMenu',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Start`,
			isMobile: true,
		},
	},
	{
		path: '/mb/opinions',
		name: 'mb-opinions',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/Opinions.vue'),
		meta: {
			layout: 'AppLayoutMobileWithBottomMenu',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Opinions`,
			isMobile: true,
		},
	},
	{
		path: '/mb/ranking',
		name: 'mb-ranking',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/Ranking.vue'),
		meta: {
			layout: 'AppLayoutMobileWithBottomMenu',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Ranking`,
			isMobile: true,
		},
	},
	{
		path: '/mb/profile',
		name: 'mb-profile',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/profile'),
		meta: {
			layout: 'AppLayoutMobileWithBottomMenu',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Profile`,
			isMobile: true,
		},
	},
	{
		path: '/mb/profile/edit',
		name: 'mb-profil-edit',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/profile/Edit.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Edycja profilu`,
			isMobile: true,
		},
	},
	{
		path: '/mb/profile/password-change',
		name: 'mb-profil-password-change',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/profile/PasswordChange.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Zmiana hasła`,
			isMobile: true,
		},
	},
	{
		path: '/mb/opinion/step-1',
		name: 'mb-opinion-select-patient',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/opinion_create'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Opinia - wybór pacjenta`,
			isMobile: true,
		},
	},
	{
		path: '/mb/opinion/step-2',
		name: 'mb-opinion-select-unit',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/opinion_create/SelectWard.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Opinia - wybór oddziału`,
			isMobile: true,
		},
	},
	{
		path: '/mb/opinion/step-3',
		name: 'mb-opinion-select-rating',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/opinion_create/SelectRating.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Opinia - wybór oceny`,
			isMobile: true,
		},
	},
	{
		path: '/mb/opinion/step-3-a',
		name: 'mb-opinion-select-rating-anonymous',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/opinion_create/SelectRatingAnonymous.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: false, // true
			requiresLocation: false,
			title: `Opinia - wybór oceny`,
			isMobile: true,
		},
	},
	{
		path: '/mb/opinion/completed',
		name: 'mb-opinion-completed',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/opinion_create/Completed.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Opinia - zakończono`,
			isMobile: true,
		},
	},
	{
		path: '/mb/opinion/completed-a',
		name: 'mb-opinion-completed-anonymous',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/opinion_create/CompletedAnonymous.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: false, // true
			requiresLocation: false,
			title: `Opinia - zakończono`,
			isMobile: true,
		},
	},
	{
		path: '/mb/opinion/share',
		name: 'mb-opinion-share',
		component: () =>
			import(/* webpackChunkName: "mobile" */ '@/views/mobile/panel/opinion_create/Share.vue'),
		meta: {
			layout: 'AppLayoutMobileLogin',
			requiresAuth: true, // true
			requiresLocation: true,
			title: `Opinia - udostepnij`,
			isMobile: true,
		},
	},
]

export default routes
