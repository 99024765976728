import { createEventFromCode } from '@/functions/events'

let listenderAdded = false

let _isServerConnectionPromise = null

export const optionsSpeedClassification = [
		{
			min: 0,
			max: 0.1,
			verdict: 'very slow',
			showWarning: true,
		},
		{
			max: 1,
			verdict: 'slow',
			isEnough: true,
		},
		{
			max: 5,
			verdict: 'normal',
			isEnough: true,
		},
		{
			max: 10,
			verdict: 'fast',
			isEnough: true,
		},
		{
			max: 15,
			verdict: 'very fast',
			isEnough: true,
		},
		{
			verdict: 'optimal',
			isEnough: true,
		},
	],
	isOnline = async function () {
		/*  https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine.onLine */
		if (!window.navigator?.onLine) {
			await createEventFromCode('network-internet-connection-fail')

			let errorMessage = `No internet access.`
			throw new Error(errorMessage)
		}

		return true
	},
	//TODO
	isServerConnection = async function () {
		if (_isServerConnectionPromise) return await _isServerConnectionPromise
		else {
			_isServerConnectionPromise = _isServerConnection()
			
			try {
				await _isServerConnectionPromise
			} catch (error) {
				_isServerConnectionPromise = null
				throw new Error(error)
			}
			
			_isServerConnectionPromise = null
		}
	},
	_isServerConnection = async function () {
		const apiRoot = `${process.env.VUE_APP_API_ROOT}/auth-service/server_status`

		//TODO set timeout
		await fetch(apiRoot)
			// eslint-disable-next-line no-unused-vars
			.then((data) => {
				//TODO check if ping is low
			})
			.catch(async () => {
				await createEventFromCode('network-server-connection-fail')

				let errorMessage = `No connection to the server.`
				throw new Error(errorMessage)
			})

		return true
	},
	classifySpeedOfConnection = function (val) {
		let option = null

		optionsSpeedClassification.forEach((o) => {
			if (
				(o.min <= val || !o.min) &&
				(o.max >= val || !o.max) &&
				option === null
			)
				option = o
		})

		if (option) return { ...option, value: val }

		let errorMessage = `[classifySpeedOfConnection] No option.`
		throw new Error(errorMessage)
	},
	getMinimumSpeedOfConnection = function () {
		const i = optionsSpeedClassification.findIndex((e) => e.isEnough)

		if (i > 0 && !optionsSpeedClassification[i].min) {
			return optionsSpeedClassification[i - 1].max
		} else if (i > -1 && optionsSpeedClassification[i].min)
			return optionsSpeedClassification[i].min

		return null
	},
	getSpeedOfConnection = function () {
		/* https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Downlink */
		const downlink = navigator?.connection?.downlink || 0

		return downlink
	},
	getEffectiveType = function () {
		/* https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType */
		const effectiveType = navigator?.connection?.effectiveType || 0

		return effectiveType
	},
	isConnectionSpeedisEnough = async function () {
		if(!navigator?.connection?.downlink){
			console.debug(`[isConnectionSpeedisEnough] no supported`)
			
			return true
		}
	
		const classified = classifySpeedOfConnection(getSpeedOfConnection())

		if (classified?.showWarning && classified?.isEnough) {
			await createEventFromCode('network-internet-speed-warning')

			return classified?.isEnough
		} else if (classified?.isEnough) {
			return classified?.isEnough
		} else {
			console.debug(`[isConnectionSpeedisEnough]`, classified)

			await createEventFromCode('network-internet-speed-fail')

			let errorMessage = `Connection speed is to low.`
			throw new Error(errorMessage)
		}
	},
	isConnectionReady = async function () {
		await isOnline()
		await isConnectionSpeedisEnough()
		// await isServerConnection() //TODO dont ping server every time?
	},
	changeConnectionHandler = async function () {
		console.debug(`[changeConnectionHandler]`)
		await isConnectionReady()
	},
	navigatorConnectionAddEventListener = async function () {
		if (!listenderAdded) {
			// console.debug(`[navigatorConnectionAddEventListener]`)
			navigator?.connection?.addEventListener(
				'change',
				async () => await changeConnectionHandler
			)

			listenderAdded = true
		}
	}

navigatorConnectionAddEventListener()

export default isConnectionReady
