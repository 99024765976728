import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import 'vuetify/dist/vuetify.min.css'

import pl from 'vuetify/src/locale/pl.ts'
import colors from '@/assets/scss/colors.scss'

Vue.use(Vuetify)

export default new Vuetify({
	icons: {
		iconfont: 'fa',
	},
	lang: {
		locales: { pl },
		current: 'pl',
	},
	theme: {
		themes: {
			light: {
				primary: colors.milanoRed,
				secondary: '#424242',
				accent: colors.accent,
				error: colors.error,
				info: '#2196F3',
				success: colors.success,
				warning: colors.warning,
				lightblue: '#14c6FF',
				yellow: '#FFCF00',
				pink: '#FF1976',
				orange: '#FF8657',
				magenta: '#C33AFC',
				darkblue: '#1E2D56',
				gray: '#909090',
				neutralgray: '#9BA6C1',
				// green: '#2ED47A',
				// red: '#FF5c4E',
				darkblueshade: '#308DC2',
				lightgray: '#BDBDBD',
				lightpink: '#FFCFE3',
				white: '#FFFFFF',
				...colors,
				
				// blue: colors.blue,
				// green: colors.green,
				// red: colors.red,
			},
			dark: {
				//primary: colors.primary,
			},
		},
	},
})
